.imgContent {
  width: 900px;
  height: auto;
  margin-top: 40px;
  margin-left: 130px;
  border-radius: 10px;
}
.formLink {
  margin-top: 30px;
  padding-left: 450px;
  .externalLink {
    text-decoration: none;
  }
}

.title {
  margin-bottom: 10px;
  margin-top: 0px;
}

.fuel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmation {
  text-align: center;
}

.table {
  margin-top: 30px;
  .item {
    display: flex;
    margin: 10px 0px;
    .label {
      color: var(--color-cement);
      width: 150px;
    }
  }
}

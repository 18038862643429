.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.search {
  width: 260px;
  margin-left: 10px;
}

.fieldContent {
  width: 260px;
}

.downloadButton {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.container {
  width: 400px;
}
.description {
  margin-top: 20px;
}

.creditCardImage {
  height: 18px;
}

.step {
  margin-top: 20px;
}

.panel {
  display: flex;
  width: 100%;
  margin-left: 10px;
  height: 30px;
  text-align: left;
}
.title {
  margin-left: 15px;
  .bankname {
    font-size: 14px;
    font-weight: 500;
    line-height: 0.5;
    color: #34384b;
  }
  .bankfee {
    font-size: 14px;
    font-weight: 300;
    line-height: 3;
    color: #a1aeb7;
  }
}
.selectedbank {
  margin-left: auto;
  margin-right: 20px;
  font-size: 17px;
  line-height: 2.2;
  color: var(--color-purple);
}

.cardInfor {
  display: flex;
  justify-content: space-between;

  .half {
    width: 48%;
  }
}

.amountSummary {
  margin-top: 20px;

  display: inline-flex;
  > div {
    margin-right: 20px;
  }
}

.continue {
  margin-top: 20px;
}

.banks {
  width: 400px;
  border: 1px solid var(--color-lavender);
  border-radius: 5px;
  margin-top: 20px;
  max-height: 350px;
  overflow-y: auto;
}


.equivalentAmount {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  p:nth-child(2) {
    margin-bottom: unset;
  }
}
.horizontal {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  padding: 10px 0px;
}

.formContainer {
  width: 100%;
}

.message {
  margin-top: 5px !important;
  font-size: 12px;
  color: var(--color-cement) !important;
}

.error {
  color: var(--color-salsa) !important;
}

.labelLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // white-space: nowrap;
}

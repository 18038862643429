.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.search {
  width: 880px;
  margin-left: 10px;
}

.fieldContent {
  width: 260px;
}

.fieldControl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}
.content {
  padding-bottom: 30px;
  border-bottom: 1px solid #ebeef7;
}
.downloadButton {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.lubes {
  background-color: #753bbd;
}

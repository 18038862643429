.container {
    margin-top: 20px;
    width: 100%;
}

.intro {
  margin-bottom: 30px;
  button span {
    font-weight: 500;
  }
  .primarySaveButton,
  .secondarySaveButton {
    height: 36px;
    width: 110px;
  }
}

.fieldContent {
    width: 350px;
}

.fieldContent {
    width: 800px;
}

.privacy {
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 0 auto;
    span {
      span {
        font-weight: bold;
      }
    }
}

.saveButton {
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .titleText {
    div {
        display: flex;
        justify-content: flex-start;
        align-items: top;
      }
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
  }
  
  .diesel {
    background-color: #e2b40d;
  }
  
  .gas91 {
    background-color: #10ac84;
  }
  
  .gas95 {
    background-color: #ee3b34;
  }
  
  .gas97 {
    background-color: #2e4491;
  }

  .lubes {
    background-color: #753bbd;
  }
  
  .content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .checkbox {
    margin-right: 15px;
  }
  
  .checked {
    color: #1373d1;
    font-weight: 500;
  }
  
  .contentProducts {
    flex-direction: row;
    display: flex;

   .product {
      margin-right: 10px;
      width: 120px
    }
  }
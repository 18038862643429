.balanceCard {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 var(--color-shadow);
  height: 100px;
}

.nameBalance {
  display: flex;
  align-items: center;
  margin-right: 20px;
  > div img {
    height: 38px;
    width: 38px;
  }
  .loaderAmount {
    width: 107px;
    height: 25px;
  }
  .loaderText {
    width: 100px;
  }
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 6.5px;
  margin-right: 16px;
  position: relative;
  .literSign {
    font-size: 12px;
    font-weight: normal;
    margin-top: 6px;
    margin-left: 4px;
    position: absolute;
  }
}

.subtitle {
  color: var(--color-cement);
  font-size: 12px;
}

.creditPrice {
  font-size: 14px;
}

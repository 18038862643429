.popoverContainer {
  :global {
    .MuiPaper-root {
      margin-left: -80px;
    }

    // .MuiPaper-root::after {
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   background: white;
    //   content: " ";
    //   z-index: 11111;
    //   right: -10px;
    //   top: 250px;
    // }
  }
}

.content {
  font-family: "Roboto";
  font-size: 14px;
  padding: 12px 20px;
  &:hover {
    font-weight: 500;
    color: var(--color-purple);
    background: var(--color-baby-blue);
  }
}

.icon {
  color: var(--color-night);
}

.panel {
  max-width: 130px;
  text-align: center;
  margin: auto;
}

.channel {
  margin-top: 10px;
  width: 600px;
}

.cashInPaymentMethodPanel {
  width: 184px !important;
}

.image {
  height: 70px;
}

.modal {
  max-width: 500px !important;
  width: 500px !important;
}

.summary {
  margin-top: 20px;
}

.field {
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;

  .value {
    width: 70%;
    margin-bottom: 5px;
  }
}

.filters {
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.lubes {
  background-color: #753bbd;
}
.popoverContainer {
  :global {
    .MuiPaper-root {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 70px;
      min-width: 180px;
    }
  }
  max-height: 200px;
  min-width: 270px;
}

.title {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-mid-blue);
}

.content {
  font-size: 14px;
  padding: 7px 20px;
  background: var(--color-white);
}

.icon {
  color: var(--color-night);
}

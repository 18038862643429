.indigo {
  color: var(--color-indigo);
}

.night {
  color: var(--color-night);
}

h1.title {
  font-size: 26px;
  font-weight: 700;
}

h3.title {
  font-size: 18px;
  font-weight: 600;
}

h2.title {
  font-size: 20px;
  font-weight: bold;
}

.driverCount {
  color: var(--color-purple);
  text-decoration: underline;
  text-transform: lowercase;
}

.driverPopperList {
  padding: 20px;

  > .title {
    font-weight: 500;
    color: var(--color-indigo);
  }

  > .name {
    margin-top: 20px;
  }
}

a {
  color: var(--color-purple);
}
.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.avatar {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
}

.modalContent {
  width: 596px;
  .formContent {
    display: flex;
    flex-direction: row;
    .defaultAvatar {
      margin-right: 30px;
      height: 128px;
      width: 128px;
    }
  }
  .content {
    width: 100%;
  }
}

.actionButton {
  display: flex;
  &.primaryOnly {
    justify-content: space-around !important;
  }
  justify-content: flex-end;
  margin-top: 25px;
  .primary {
    margin-left: 10px;
  }
  .button {
    padding: 5px 20px;
    height: 36px;
    min-width: 110px;

    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.privacy {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 0 auto;
  span {
    span {
      font-weight: bold;
    }
  }
}
.content {
  width: 30%;
}

.pending {
  margin: 10px 0 0 0;

  span {
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-purple);
    font-weight: bold;
  }
}

.subTitle {
  margin: 15px 0 10px 0;
  color: var(--color-cement);
  span {
    font-weight: bold;
  }
}

.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
  .mapInfo {
    height: 100%;
    padding: 20px;
    overflow-x: auto;
    .numberOfStations {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .stationListMaploading {
      margin-top: 20px;
      padding-top: 20px;
      border-top: solid 1px var(--color-light-grey-2);
      color: var(--color-night);
    }
    .backlink {
      display: inline-block;
      margin-left: 10px;

      cursor: pointer;
      color: var(--color-sky-2);
      font-weight: bold;
      .backlinkContent {
        display: flex;
        gap: 20px;
        span {
          margin-top: 3px;
        }
        .text {
          color: var(--color-sky-2);
          font-size: 14px;
        }
      }
    }
  }
}

.city {
  text-transform: capitalize;
}

.openTag {
  color: var(--color-emerald);
}

.closeTag {
  color: var(--color-salsa);
}

.bold {
  font-weight: 500;
}

.stationName,
.listInMap {
  display: flex;
  flex-direction: row;
  gap: 20;
  .stationLogo {
    margin-top: 5px;
    margin-right: 15px;
    width: 28px;
    height: 28px;
  }
  .locationDirection {
    display: flex;
    .kmAway {
      margin-right: 5px;
    }
    .directionContaier {
      display: flex;
      cursor: pointer;
      .directionIcon {
        margin-top: 3px;
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
      .directionText {
        font-weight: 500;
        color: var(--color-purple);
      }
    }
  }
}
.listInMapContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--color-light-grey-2);
  margin-top: 20px;
  padding-top: 20px;
  .listInMap {
    width: 97%;
    .stationDetailsIcon {
      display: block;
      align-items: center;
      width: 37px;
      .stationLogo {
        margin-top: -2px;
        width: 24px;
        height: 24px;
      }
      .stationScheduleIcon,
      .stationLocationIcon {
        margin-top: 10px;
        margin-left: 4px;
        width: 15px;
        width: 15px;
      }
    }
    .schedule,
    .address {
      display: flex;
      gap: 10px;
      margin-top: 15px;
    }
    .locationDirection {
      margin-top: 10px;
    }
  }
  .seeDetailsButton {
    width: 3%;
    margin-left: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
}

.productDetails {
  border-top: solid 10px var(--color-light-grey-3);
  margin-right: -20px;
  margin-left: -20px;
  margin-top: 30px;
  padding: 30px 60px;
  .productList {
    margin-top: 10px;
  }
  .productListContent {
    font-size: 12px;
  }
}

.limitedStations {
  padding: 2px 10px 2px 10px;
  margin-right: 7px;
  border-radius: 10px;
  background-color: var(--color-cheddar);
  color: var(--color-white);
  font-weight: 500;
  font-size: 12px;
}

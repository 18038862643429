.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dateRange {
  width: 250px;
  margin: auto;
  margin-right: 0px;
}

.productCode {
  margin: 20px -1.2rem;
}

.search {
  width: 35%;
  margin-left: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: var(--color-gold);
}

.gas91 {
  background-color: var(--color-emerald);
}

.gas95 {
  background-color: var(--color-salsa);
}

.gas97 {
  background-color: var(--color-deep-blue);
}

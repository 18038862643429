.title {
  background-color: #f9fafb;
  border-top: 1px solid #ebeef7;

  color: #a1aeb7;
  font-weight: 600;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.checkbox {
  margin-right: 15px;
}

.checked {
  color: #1373d1;
  font-weight: 500;
}

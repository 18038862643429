.container {
  text-align: center;
  width: max-content;
  cursor: pointer;
}

.panel {
  border: 1px solid #753bbd;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.inactive {
  .panel {
    border: 1px solid #e4e5ec;
    background: rgba($color: #ffffff, $alpha: 0.3);
  }
  .title {
    opacity: 0.4;
  }
}

.disabled {
  cursor: not-allowed;
}

.title {
  margin-top: 10px;
}

.panels {
  display: inline-flex;
  max-width: 100%;
  flex-wrap: wrap;

  .panelItem {
    float: left;
    margin-bottom: 10px;
  }

  .panelItem:not(:last-child) {
    margin-right: 10px;
  }
}

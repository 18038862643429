.filter {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 30px;
  .graphType {
    width: 300px;
  }
  .dateSelection {
    width: 200px;
  }
}
.durationOptions {
  width: 100%;
}
.dateRange {
  margin-left: 7px;
}

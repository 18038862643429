.checkbox {
  margin-left: -10px !important;
}

:global {
  .MuiSvgIcon-root {
    color: var(--color-purple);
  }
}

.disabled {
  cursor: not-allowed;
  :global {
    .MuiSvgIcon-root {
      color: var(--color-cement);
    }
  }
}

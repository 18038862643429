.container {
  position: relative;
  cursor: pointer;
}

.popper {
  position: absolute;
  max-height: 270px;
  overflow: scroll;
  width: 300px;
  right: -320px;
  top: -15px;
  border-radius: 10px;
  box-shadow: 0 5px 5px 5px rgb(0 0 0 / 5%);
  background-color: #fafafa;
  z-index: 1;
  display: none;

  &.active {
    display: block;
  }
}

.datePicker {
  z-index: 11111111111111111111;
}

.datePickerContainer {
  z-index: 11;
  background: #ffffff;
  height: 350px;
  margin-left: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0.3px 0.3px 5px 5px rgb(0 0 0 / 5%);
}

.dateRange {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.icon {
  font-size: 18px;
  // margin: auto;
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 10%);
  z-index: 111111;
}

.dimmerDropdown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 10%);
  z-index: 11;
}

:global {
  [role="tooltip"] {
    z-index: 11111111111111111111;
  }

  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrDayActive {
    // background-color: #753bbd2f !important;
    .rdrDayNumber span {
      color: var(--color-purple) !important;
    }
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: var(--color-purple) !important;
    color: var(--color-purple) !important;
  }

  .rdrStartEdge + span + .rdrDayNumber span,
  .rdrEndEdge + span + .rdrDayNumber span,
  .rdrStartEdge + .rdrDayNumber span,
  .rdrEndEdge + .rdrDayNumber span {
    color: #ffffff !important;
    font-weight: 500;
  }

  .rdrStartEdge:not(.rdrEndEdge) + span + .rdrDayNumber,
  .rdrEndEdge:not(.rdrStartEdge) + span + .rdrDayNumber,
  .rdrStartEdge:not(.rdrEndEdge) + .rdrDayNumber,
  .rdrEndEdge:not(.rdrStartEdge) + .rdrDayNumber {
    background-color: #753bbd2f !important;
    width: 92% !important;
    height: 26px !important;
    padding: 0px !important;
    align-items: center;
    display: flex;
    text-align: center;
  }

  .rdrEndEdge + span + .rdrDayNumber,
  .rdrEndEdge + .rdrDayNumber {
    border-radius: 0px 50% 50% 0px !important;
  }
  .rdrStartEdge + span + .rdrDayNumber,
  .rdrStartEdge + .rdrDayNumber {
    margin-left: 2px !important;
    border-radius: 50% 0px 0px 50% !important;
  }

  .rdrInRange {
    background-color: #753bbd2f !important;
    color: var(--color-purple) !important;
  }

  .rdrInRange + span + .rdrDayNumber span,
  // .rdrInRange + .rdrDayStartPreview + .rdrDayNumber span,
  // .rdrInRange + .rdrDayEndPreview + .rdrDayNumber span,
  .rdrInRange + .rdrDayNumber span {
    color: var(--color-purple) !important;
    font-weight: 500;
  }

  .rdrEndEdge,
  .rdrStartEdge {
    border-radius: 100% !important;
    height: 27px;
    width: 27px;
    margin: auto;
  }

  .rdrCalendarWrapper {
    width: 500px !important;
  }

  .rdrNextPrevButton {
    background-color: transparent !important;
  }
  .rdrPprevButton i {
    border-color: transparent #d0d2d6 transparent transparent !important;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent #d0d2d6 !important;
  }

  // .MuiPopover-root {
  //   z-index: 111111111;
  //   position: absolute !important;
  // }
}

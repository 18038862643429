.cardContainerHedging{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
  min-height: 100px;
  max-width: 1280px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  min-height: 100px;
  max-width: 1280px;
  .priceMovementContent {
    padding: 20px 20px 0px 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 var(--color-shadow);
    background-color: var(--color-white);
    .headerTitle {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-indigo);
      margin-bottom: 6px;
    }
    .headerSubtitle {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
    }
    .priceUpdateSummary {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      padding-bottom: 50px;
      border-bottom: solid 1px var(--color-lavender);
      .latestPriceMovement {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
      .lifetimeSavings {
        text-align: right;
        .amount {
          height: 20px;
          width: 100%;
          margin: 5px 0 5px 0;
        }
        .link {
          color: var(--color-purple);
          cursor: pointer;
          text-decoration: underline;
        }
        .loaderSavings {
          width: 70px;
          float: right;
        }
      }
    }
    .priceMovementHistory {
      margin-top: 30px;
      padding-bottom: 20px;
    }
    canvas {
      margin-right: -20px;
      margin-left: -20px;
      padding: 10px;
      background-color: var(--color-lavender-2);
      border-radius: 0px 0px 10px 10px;
    }
  }
  .rightCardsSection{
    width: 37.5%;
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    .linkVip{
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 var(--color-shadow);
      background-color: var(--color-white);
      overflow: hidden;
      .vipLinkImage{
        display: block;
        width: 100%;
        height: 147px;
        object-fit: cover;
      }
      .vipContent{
        display: flex;
        flex-direction: column;
        padding: 0px 20px 10px 20px;
        .vipCardBody{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .vipDetails{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 15px;
            margin-bottom: 15px;
          }
          .vipPoints{
            margin-top: 15px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
          }
         
        }
        .vipCardFooter{
          display: flex;
          justify-content: flex-end;
          .redeem{
            padding: 5px;
            margin-top: -10px
          }
        }
       
      }
    }

    .UnlinkVip{
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 var(--color-shadow);
      background-color: var(--color-white);
      overflow: hidden;
      .vipUnLinkImage{
        display: block;
        width: 100%;
        object-fit: cover;
      }
      .vipUnlinkContent{
        display: flex;
        flex-direction: row;
        ol{
          margin: 15px 5px;
        }
        ol li {
          padding: 5px;
          span {
            color: '#a1aeb7'
          }
        }
        
      }
      .inputMobileNumber{
        display: flex;
        flex-direction: row;
        margin: 0 30px;
        justify-content: space-between;
        .fieldLabel{
          width: 60%;
          .flagAndMobileInitial{
            display: flex;
            align-items: center;
          }
          .flagPhilippines{
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin: 0 4px
          }
        }
        .linkAccountButton{
          margin-top: 10px;
        }
      }

    }
    .consumptionContent {
      padding: 20px 20px 0px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 var(--color-shadow);
      background-color: var(--color-white);
      .headerTitle {
        font-size: 16px;
        font-weight: bold;
        color: var(--color-indigo);
        margin-bottom: 6px;
      }
      .headerSubtitle {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
      }
      .priceUpdateSummary {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding-bottom: 50px;
        border-bottom: solid 1px var(--color-lavender);
        .latestPriceMovement {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        .lifetimeSavings {
          text-align: right;
          .amount {
            height: 20px;
            width: 100%;
            margin: 5px 0 5px 0;
          }
          .link {
            color: var(--color-purple);
            cursor: pointer;
            text-decoration: underline;
          }
          .loaderSavings {
            width: 70px;
            float: right;
          }
        }
      }
      .priceMovementHistory {
        margin-top: 30px;
        padding-bottom: 20px;
      }
      canvas {
        margin-right: -20px;
        margin-left: -20px;
        padding: 10px;
        background-color: var(--color-lavender-2);
        border-radius: 0px 0px 10px 10px;
      }
    }

    .locqpayBalanceContent {
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 var(--color-shadow);
      background-color: var(--color-white);
      overflow: hidden;
      position: relative;
      text-align: center;
     
      .locqpayBalanceImage{
        display: block;
        width: 100%;
        object-fit: cover;
      }
      .locqpayBalance{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display:flex; 
        flex-direction:column;
        .locqpayBalanceAmount{
          color: #ffffff;
          font-size: 24px;  
          font-weight: bold;
        }
        .locqpayBalanceText{
          color: #ffffff;
          font-size: 14px;  
          text-align: left;
        }
      }
     
    }
    
    .activeFuelCodeContent {
      padding: 20px 20px 0px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 var(--color-shadow);
      background-color: var(--color-white);
      .headerTitle {
        font-size: 16px;
        font-weight: bold;
        color: var(--color-indigo);
        margin-bottom: 6px;
      }
      .headerSubtitle {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
      }
      .priceUpdateSummary {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding-bottom: 50px;
        border-bottom: solid 1px var(--color-lavender);
        .latestPriceMovement {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        .lifetimeSavings {
          text-align: right;
          .amount {
            height: 20px;
            width: 100%;
            margin: 5px 0 5px 0;
          }
          .link {
            color: var(--color-purple);
            cursor: pointer;
            text-decoration: underline;
          }
          .loaderSavings {
            width: 70px;
            float: right;
          }
        }
      }
      .priceMovementHistory {
        margin-top: 30px;
        padding-bottom: 20px;
      }
      canvas {
        margin-right: -20px;
        margin-left: -20px;
        padding: 10px;
        background-color: var(--color-lavender-2);
        border-radius: 0px 0px 10px 10px;
      }
    }
  }

  .priceMovementContent {
    width: 60.5%;
    min-height: 670px;
    .upcomingPriceMovement {
      margin-top: -20px;
      margin-right: -20px;
      margin-left: -20px;
      margin-bottom: 20px;
      border-radius: 10px 10px 0px 0px;
    }
  }

  .consumptionContent {
    // width: 37.5%;
    height: 250px;
    .body {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 420px;
    }
  }
  .activeFuelCodeContent {
    // width: 37.5%;
    height: 250px;
    .body {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      width: 420px;
    }
  }
}

.surveyStar{
  width: 322px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 2px 0 rgba(66, 75, 90, 0.24);
  background-color: #504e8b;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  bottom: 5px;
  right: 100px;
  justify-content: space-between;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
    .icon {
      color: #ffffff;
      cursor: pointer;
    }
  }
  .headerTitleRating{
    padding: 10px 20px 10px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .bodyThankYou{
    padding: 5px 20px 30px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .buttonStar {
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .buttonStar  > button{
   background-color: unset;
   border: unset;
  }

  .buttonStar  > button &:hover{
    background-color: #e4e6ea;
    border: unset;
   }
  .skip{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #f7f5ff;
    text-decoration: underline;
    text-align: center;
    padding: 20px;
    background-color: transparent;
    &:hover{
      background-color: transparent;
      background: unset;
      border: unset;
    }
  }

  .firstSkip{
    display: flex;
    justify-content: center;
    padding: 15px;
    .skipFirst{
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #f7f5ff;
      text-decoration: underline;
      text-align: center;
      padding: 20px;
      background-color: transparent;
      &:hover{
        background-color: transparent;
        background: unset;
        border: unset;
      }
    }
  }

  .successFeedback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 15px 5px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .icon {
        color: #ffffff;
        cursor: pointer;
      }
    }


  }


  .checkBoxContainer {
    margin: 10px 20px 10px;
    .checkBoxLabel {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 282px;
      height: auto;
      padding: 1px 15px;
      border-radius: 8px;
      border: solid 1px #ebeef7;
      background-color: #f7f5ff;
      .description {
        color: #753bbd;
        text-align: center;
        size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
      }
    }

    .checkBoxLabelOther{
      display: flex;
      flex-direction: column;
      width: 282px;
      height: auto;
      padding: 1px 15px;
      border-radius: 8px;
      border: solid 1px #ebeef7;
      background-color: #f7f5ff;
      .checkBoxLabelHeader{
        display: flex;
        align-items: center;
      }
      .description {
        color: #753bbd;
        text-align: center;
        size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
      }
    }
  }

  .skipAndSubmitUnsatisfactory{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .skipUnsatisfactory{
      margin: 0 5px;
    }
  }

  .skipAndSubmitSatisfactory{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .skipSatisfactory{
      margin: 0 5px;
    }
  }
  .ratingGood{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 20px 0;
    cursor: pointer;
    .textRatingGood{
      width: 172px;
      cursor: pointer;
      border-radius: 4px;
      height: 36px;
      padding: 5px;
      background-color: #e4e6ea;
      color: #504e8b;
      text-align: center;
      size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
 
  .noRating{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #a1aeb7;
    text-align: center;
    padding-top: 15px;
  }
}

.on {
  color: #e4e6ea;
  width: auto;
  height: auto;
}
.off {
  color: #a9acc6;
  width: auto;
  height: auto;
}

.star{
 font-size: 25px;
}

.upcomingPriceMovement {
  height: 34px;
  width: auto;
  padding: 7px 20px 7px 20px;
  background-color: var(--color-beige);

  .textContainer {
    font-size: 12px;
    line-height: 1.9;
    color: var(--color-night-2);
    .loader {
      height: 25px;
      width: auto;
    }
  }
}

.container {
  display: inline-block;
  .fuelTypeName {
    padding-left: 7px;
    margin-right: 5px;
    color: var(--color-night-2);
    font-weight: 400;
  }
  .price {
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }
  .rollback {
    color: #10ac84;
  }
  .increase {
    color: #ee3b34;
  }
  .noMovement {
    color: var(--color-cement);
  }
  .arrow {
    font-size: 15px;
    font-weight: 700;
    margin-right: 5px;
  }
}

.filter {
  display: flex;
  align-items: flex-end;
}

.status {
  width: 130px;
  margin-right: 10px;
}

.search {
  width: 400px;
}

.filter {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.date {
  margin-right: 10px;
  width: 20%;
}

.productCode {
  width: 30%;
  margin-right: 10px;
}

.search {
  width: 50%;
}

.downloadButton {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.container {
  width: 1100px;
  margin-top: 100px;
}

.backlinkText {
  font-weight: 500;
  color: var(--color-purple);
  text-decoration: underline;
}

.backlinkContent {
  display: flex;
  align-items: center;
  display: row;
}

.backlink {
  display: inline-block;
  cursor: pointer;
}

.priceMovement {
  margin-right: 50px;
  .productLabel {
    display: flex;
    .colorCodeContainer {
      width: 30px;
      .colorCode {
        font-size: 10px;
        margin-top: 5px;
        margin-left: 10px;
      }
    }
    .fuelTypeName {
      margin-bottom: 5px;
      color: var(--color-cement);
    }
  }

  .movement {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .price {
      font-size: 16px;
      font-weight: bold;
    }
    .rollback {
      color: var(--color-emerald);
    }
    .increase {
      color: var(--color-salsa);
    }
    .noMovement {
      color: var(--color-cement);
    }
    .gasProduct {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-night);
    }
    .arrowContainer {
      width: 20px;
      .arrow {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .loader {
      height: 25px;
      width: 50px;
    }
  }
}

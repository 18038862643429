.forgotContainer {
  margin-top: 30px;
  .title {
    font-size: 16px !important;
    margin-top: 0px;
    line-height: 26px;
  }

  .buttonGroup {
    display: flex;
    button {
      width: 110px;
      height: 36px;
      margin: 20px 20px 0 0;
      font-weight: bold;
    }
    .redirectLogin {
      width: 177px;
      font-weight: normal;
    }
  }
}

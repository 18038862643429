.intro {
  margin-bottom: 30px;
}

.table {
  margin-bottom: 30px;
}

.empty {
  min-height: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    color: var(--color-cement);
    margin-bottom: 5px;
  }

  :nth-child(2) {
    margin-top: 10px;
  }
}

.continue {
  height: 39px;
  padding: 12px 40px;
}

.editFuelCodeModal {
  width: 1100px;
  margin-top: 100px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  .primary {
    margin-left: 10px;
  }
  button {
    font-weight: 500;
    height: 36px;
  }

  .cancel {
    padding: 10px 30px;
  }
}

.modal {
  width: 418px;
}

.message {
  font-size: 14px;
  padding: 10px;
}

.infoMessage {
  color: var(--color-salsa);
}

.content {
  margin-top: 10px;
}

.inquiryId {
  font-size: large;
  color: var(--color-cement);
  span {
    color: var(--color-night);
    margin-left: 30px;
    font-weight: bold;
  }
}

.container {
  width: 100%;
}

.panel {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.panelItem {
  text-align: center;
  width: max-content;
  width: 100%;
  transition: background 1s;
  &:hover {
    background: var(--color-baby-blue);
  }
}

.title {
  margin-top: 10px;
}

.panels {
  display: block;
  max-width: 100%;

  // .panelItem {
  //   float: left;
  //   border: 1px solid var(--color-lavender);
  // }

  .panelItem:not(:last-child):not(:last-child) {
    border-top: 0px;
    border-bottom: 1px solid var(--color-lavender);
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.enabled {
  cursor: pointer;
}

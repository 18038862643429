.filter {
  display: flex;
  align-items: flex-end;
}

.status {
  width: 100px;
  margin-right: 10px;
}

.search {
  width: 300px;
}

:root {
  --color-purple: #753bbd;
  --color-deep-blue: #2e4491;
  --color-baby-blue: #f0effa;
  --color-soft-blue: #babfdf;
  --color-sky: #1776f2;
  --color-sky-2: #3b81d5;
  --color-indigo: #44498d;
  --color-deep-red: #de2f28;
  --color-salsa: #ee3b34;
  --color-grass: #019f3e;
  --color-emerald: #10ac84;
  --color-cheddar: #ffaa01;
  --color-gold: #e2b40d;
  --color-cement: #a1aeb7;
  --color-concrete: #f6f6f6;
  --color-whitesmoke: #f5f5f5;
  --color-ash: #e2e7ee;
  --color-steel: #d0d2d6;
  --color-night: #34384b;
  --color-night-2: #343434;
  --color-blush: #fdecea;
  --color-white: #ffffff;
  --color-light-grey: #f9fafb;
  --color-light-grey-2: #ebeef7;
  --color-light-grey-3: #f7f8fd;
  --color-snow: #fafafa;
  --color-aliceblue: #f8efff;
  --color-lavender: #e4e5ec;
  --color-shadow: #e8e7eb;
  --color-lavender-2: #f2f4fc77;
  --color-beige: #fef4d9;
}

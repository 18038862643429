.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.avatar {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
}

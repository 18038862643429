.container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image {
  width: 200px;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 470px;
  margin: auto;
}

.MuiAutocomplete-option:hover {
  background: var(--color-baby-blue);
  color: var(--color-purple);
  font-weight: 500;
}
.MuiListItem-root.MuiMenuItem-root {
  font-size: 14px;
}

.MuiListItem-root.MuiMenuItem-root:hover {
  background: var(--color-baby-blue);
  color: var(--color-purple);
  font-weight: 500;
}

* {
  font-family: Roboto;
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.gray {
  color: var(--color-cement);
}

.min-100 {
  min-width: 100px;
}

.min-80 {
  min-width: 80px;
}

.min-70 {
  min-width: 70px;
}

.min-60 {
  min-width: 60px;
}

.min-50 {
  min-width: 50px;
}

.nowrap {
  word-break: keep-all;
  white-space: nowrap;
}

.link {
  color: var(--color-sky-2);
  text-decoration: underline;
}

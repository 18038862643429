.container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .content {
    display: inline-block;
    align-items: center;
    text-align: center;
    .movementDate {
      margin-bottom: 10px;
      color: var(--color-night);
    }
    .priceMovement {
      display: flex;
      justify-content: center;
      align-items: center;
      .price {
        font-size: 10px;
      }
    }
  }
}

.container {
  text-align: center;
  margin: -42px -30px 0px -34px;
  background-repeat: no-repeat;
  background-size: contain;
}

.image {
  width: 100%;
  height: 130px;
  background: #f2f4fc;
  display: flex;
  align-items: center;
  text-align: center;
}

.defaultImage {
  height: 80px;
  width: unset !important;
}

.img {
  max-height: 130px;
  margin: auto;
  // width: 100%;
}

.header {
  margin-bottom: 35px;
  padding-top: 40px;
}

.content {
  text-align: left;
  padding: 20px;
  height: 150px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  .title {
    margin-bottom: 10px;
  }
}

.button {
  margin-top: 30px;
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  // &:not(:first-child) {
  margin-left: 10px;
  // }

  span {
    font-weight: 500;
    font-size: 14px;
  }
}

.noSearchResultRender {
  margin-top: 80px;
}

.gridContent {
  max-width: 1000px;
  margin: auto;
}

.details {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewDetails {
  cursor: pointer;
}

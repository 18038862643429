.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  .primary {
    margin-left: 10px;
  }
  button {
    font-weight: 500;
    height: 36px;
  }

  .cancel {
    padding: 10px 30px;
  }
}

.modal {
  width: 580px;
}

.content {
  margin-top: 10px;
}

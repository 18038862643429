.button {
  text-transform: none;
  min-height: 36px;
  font-weight: 400;
  padding: 5px 20px;
  box-shadow: none;
  height: 36px;
}

.primary {
  color: #ffffff;
  background: linear-gradient(90deg, rgba(68, 73, 141, 1) 0%, rgba(117, 59, 189, 1) 100%);
}

.survey {
  color: #504e8b;
  background-color: #e4e6ea;
  font-weight: bold;
}

.secondary {
  background: unset;
  padding: 0;
  color: var(--color-cement);
  font-weight: 500;
  &:hover {
    background: unset;
    box-shadow: unset;
  }
}

.rounded {
  border-radius: 20px;
}

.circular {
  background: none;
  width: 20px !important;
  height: 20px !important;

  &.primary {
    color: #ffffff;
  }
}

.disabled {
  // filter: grayscale(80%);
  background: var(--color-steel) !important;
  color: #ffffff !important;
}

.link {
  background: unset;
  text-decoration: underline;
  text-decoration-color: var(--color-purple);
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-purple);
  font-weight: 500;
}

.link:hover,
.link:active {
  box-shadow: unset;
  background: unset;
  text-decoration: inherit;
}

.outline {
  background: unset;
  color: var(--color-purple);
  border: 2px solid;

  padding: 7px 20px;
}

.outline:hover {
  background-color: var(--color-baby-blue);
  box-shadow: unset;
}

.thin {
  border: 1px solid;
}

.exportbutton {
  background: var(--color-aliceblue);
  transition: background 0.5s;
  span {
    color: var(--color-purple);
    font-weight: 500;
    align-items: center;
    display: flex;
  }
  &:hover,
  &.active {
    background: var(--color-purple);
    span {
      color: var(--color-white);
    }
  }
}

.tabSelectable {
  // filter: grayscale(80%);
  color:var(--color-steel) !important;
}
.tiny {
  text-transform: none;
  min-height: 26px;
  border: 1px solid;
  font-size:13px;
  font-weight: 500;
  padding: 2px 8px;
  box-shadow: none;
  height: 26px;
}

.panel {
  max-width: 130px;
  text-align: center;
  margin: auto;
}

.channel {
  margin-top: 30px;
}

.cashInPaymentMethodPanel {
  width: 184px !important;
}

.image {
  width: 160px;
}

.modal {
  max-width: 500px !important;
  width: 500px !important;
}

.summary {
  margin-top: 20px;
}

.field {
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;

  .value {
    width: 70%;
    margin-bottom: 5px;
  }
}

.form {
  width: 100%;
}

.intro {
  margin: 0 0 25px 0;
}

.container {
  width: 400px;
}

// .input,
// .total {
//   margin-top: 15px;
// }

.radio {
  margin-right: 70px;
}

.error {
  font-size: 12px;
  margin-top: -5px;
  color: var(--color-deep-red);
  display: block;
  margin-bottom: 10px;
}

.image {
  width: 100%;
  height: 270px;
  background: #f2f4fc;
  display: flex;
  align-items: center;
  text-align: center;
}

.defaultImage {
  height: 100px !important;
  width: unset !important;
}

.img {
  margin: auto;
  // width: 100%;
  height: 100%;
}

.container {
  padding: 0px;
  overflow: hidden;
}

.content {
  min-height: 200px;
  text-align: center;
  padding: 30px;
  max-height: 300px;
  overflow-x: auto;
}

.title {
  margin-bottom: 2px;
  margin-top: 0px;
}

.description {
  margin-top: 40px;
  word-break: normal;

  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.button {
  margin-top: 30px;
  span {
    font-weight: 500;
  }
}

.close {
  background: var(--color-baby-blue);
  color: var(--color-night);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 10px;

  position: absolute;
  .icon {
    font-size: 14px;
    margin: auto;
    transition: font-size 0.1s;
  }
  &:hover {
    .icon {
      font-size: 14.5px;
    }
  }
}

.imageContainer {
  display: flex;
  align-items: flex-start;
  text-align: right;
  justify-content: flex-end;
}

.consumptionCard {
  display: block;
  margin-bottom: 20px;
  width: 50%;
  cursor: pointer;
  .productHeader {
    display: flex;
    align-items: center;
    width: auto;
    .productName {
      color: var(--color-cement);
    }
  }
  .loaderProduct,
  .loaderAmount {
    width: 60px;
    height: 24px;
  }
  .loaderAmount {
    margin-top: 7px;
    margin-left: 20px;
  }
}
.nameBalance {
  display: flex;
  align-items: center;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 24px;
    position: relative;
    .literSign {
      font-size: 12px;
      font-weight: normal;
      margin-top: 6px;
      margin-left: 4px;
      position: absolute;
    }
  }
}

.productColor {
  width: 25px !important;
}

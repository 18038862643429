.container {
  text-align: center;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 470px;
  margin: auto;
  font-size: 20;
  color: #95a2aa;
}

.subtitle{
  margin-bottom: 10px;
}

.inputDiv{
  display: flex;
  justify-content: space-around;
}

.inputDiv >  div:nth-child(1){
  display: flex;
  align-items: center;
}

.inputDiv >  div:nth-child(2){
  width: 50%;
}
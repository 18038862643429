.inputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.disabled {
  background: var(--color-concrete);
  color: rgba(0, 0, 0, 0.38) !important;
  border: none !important;
  :global {
    .DayPickerInput > input {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

.inputFromTo {
  display: flex;
  align-items: center;
  border-color: var(--color-ash);
  height: 38px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  cursor: pointer;

  :global {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      // background-color: #f0f8ff !important;
      background-color: var(--color-baby-blue) !important;
      color: var(--color-purple);
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }
    .DayPicker-Day--start {
      background-color: var(--color-purple) !important;
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day.DayPicker-Day--today {
      color: #000000;
    }
    .DayPicker-Day--end {
      background-color: var(--color-purple) !important;
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .DayPickerInput-Overlay {
      width: 600px;
      margin-left: -10px;
      margin-top: 7px;
      border-radius: 10px;
    }
    .DayPickerInput > input {
      border: unset;
      background-color: inherit;
      cursor: pointer;
      &::placeholder {
        color: #9f9f9f;
        font-weight: 500 !important;
        font-family: Roboto;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
    .DayPickerInput > input:focus {
      outline: 0;
    }
  }
}

.startDate {
  width: 35%;
  :global {
    .DayPickerInput > input {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.to {
  width: 10%;
  color: #9f9f9f;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.endDate {
  width: 45%;
  :global {
    .DayPickerInput > input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.icon {
  object-fit: unset;
  height: 20px;
  width: 20px;
}

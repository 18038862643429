.modal {
  width: 500px;
}

.status {
  text-align: center;
}

.table {
  margin-top: 30px;
  .item {
    display: flex;
    margin: 20px 0px;
    .label {
      color: var(--color-cement);
      width: 150px;
    }
  }
}

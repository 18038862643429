.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-purple);
  }
  .icon {
    width: 45px;
  }
}

.content {
  text-align: center;
  p {
    margin-top: 15px;
  }
}

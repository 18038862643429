.container {
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow-y: auto;
}

.title {
  margin-top: 0px;
  line-height: 26px;
}

.subtitle {
  font-size: 16px !important;
  margin-bottom: 18px;
}

.header {
  height: 29px;
  padding: 14px 28px;
  .logoImage {
    height: 29px;
  }
}

.activateAccount {
  width: 100%;
}

.panel {
  margin-top: 36px;
  > div {
    width: 100%;
    > p {
      width: 345px;
    }
  }
}

.content {
  height: calc(100% - 78px);
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-left: 238px;
}

.contentContainer {
  display: flex;
  align-items: center;
  max-width: 800px;
  min-width: 800px;
}

.activateButton {
  width: 128px;
  height: 39px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 24px;
}

.labelMessage {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}

.container {
  display: flex;
  margin-top: 25px;
  gap: 10px; // Add gap between buttons

  &.left {
    text-align: left;
    width: 100%;
    justify-content: flex-start;
  }

  &.right {
    text-align: right;
    width: 100%;
    justify-content: flex-end;
  }

  &.center {
    text-align: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    padding: 5px 15px; // Adjust padding to avoid overflow
    height: 36px;
    min-width: 110px;
    max-width: 100%; // Allow the button to grow within its container
    white-space: nowrap; // Prevent wrapping
    overflow: hidden; // Hide overflow text
    text-overflow: ellipsis; // Add ellipsis for overflowing text
    box-sizing: border-box; // Ensure padding and border are included in the width calculation

    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

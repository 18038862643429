.container {
  text-align: center;
  width: max-content;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel {
  width: 458px;
  position: relative;

  .panelItem {
    height: 41px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    align-items: center;
    border: none;

    .addCard {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 41px;
      width: 100%;
      font-weight: 500;
      color: var(--color-purple);
      .addIcon {
        font-size: 24px;
        margin-right: 12px;
      }
    }

    .addCardDisabled {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 41px;
      width: 100%;
      font-weight: 500;
      color: var(--color-cement);
      .addIcon {
        color: var(--color-cement);
        font-size: 24px;
        margin-right: 12px;
      }
    }

    .noCards {
      font-weight: 500;
      height: 41px;
      width: 100%;
      justify-content: center;
      color: var(--color-cement);
    }

    .cardInfo {
      position: relative;
      flex-direction: row;
      align-items: center;
      display: flex;
      border: 1px solid var(--color-lavender);
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      width: 88%;
      .cardDetails {
        flex-direction: row;
        align-items: center;
        display: flex;
        .icon {
          width: 40px;
          height: 40px;
          object-fit: contain;
          margin-right: 20px;
        }

        label {
          font-weight: 500;
          color: var(--color-night);
        }
      }
    }

    .delete {
      height: 20px;
      width: 20px;
    }

    .deleteDisabled {
      color: var(--color-cement);
      height: 20px;
      width: 20px;
    }
  }

  .panelItem:first-child .cardInfo {
    border-radius: 5px 5px 0px 0px;
  }

  .panelItem:not(:first-child):not(:last-child) .cardInfo {
    border-top: 0px;
  }

  .panelItem:last-child .cardInfo {
    border-top: 0px;
    border-radius: 0px 0px 5px 5px;
  }
}

.container {
  height: initial;
  align-items: center;
  display: flex;
}

.icon {
  color: #ffffff;
}

.content {
  display: flex;
}

.count {
  background: #ee3b34;
  color: #ffffff;
  font-size: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  margin-top: -8px;
  margin-left: -10px;
  span {
    margin: auto;
  }
}

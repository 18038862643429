.filters {
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.content {
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.button {
  margin-top: 30px;
  height: 39px;
}

/* tooltip.module.scss */
.customTooltip {
  background-color: #fff;  /* White background */
  color:#34384b;        /* Black text */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* Light shadow */
  font-size: 12px;         /* Adjust font size to match the image */
  border-radius: 4px;      /* Slight border-radius */
  padding: 8px;            /* Padding for spacing */
}

.customArrow {
  color: #fff;             /* Arrow matches the white background */
}

.selectFuel {
  margin: 10px 0;
  width: 400px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  > .title {
    font-size: 16px;
    font-weight: 500;
  }

  > .link {
    color: var(--color-purple);
    text-decoration: underline;
    font-weight: 500;
  }
}

.inputFuelAmountContainer {
  margin-top: 30px;
}

.continue {
  margin-top: 30px;
  height: 39px;
  padding: 12px 40px;
}

.container {
  width: 100%;
}

.dropdown {
  width: 100%;
  height: 38px;

  :global {
    .MuiSelect-root.MuiSelect-select:focus {
      background: none;
    }

    fieldset {
      border-color: rgba(0, 0, 0, 0.082);
    }
  }
}

.placeholder {
  color: var(--color-cement);
}

.disabled {
  background: var(--color-concrete);
  :global {
    .MuiOutlinedInput-notchedOutline {
      border: 0px solid !important;
    }
  }
}

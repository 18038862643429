.filters {
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.avatar {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
}

.modalContent {
  width: 596px;
  .formContent {
    display: flex;
    flex-direction: row;
    .defaultAvatar {
      margin-right: 30px;
      height: 128px;
      width: 128px;
    }
  }
  .content {
    width: 100%;
  }
}

.actionButton {
  display: flex;
  &.primaryOnly {
    justify-content: space-around !important;
  }
  justify-content: flex-end;
  margin-top: 25px;
  .primary {
    margin-left: 10px;
  }
  .button {
    padding: 5px 20px;
    height: 36px;
    min-width: 110px;

    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

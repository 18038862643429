.container {
  width: 400px;
}
.description {
  margin-top: 20px;
}

.creditCardImage {
  height: 18px;
}

.step {
  margin-top: 20px;
}

.cardInfor {
  display: flex;
  justify-content: space-between;

  .half {
    width: 48%;
  }
}

.selectCards {
  margin-top: 12px;
}

.saveCard {
  display: flex;
  align-items: center;
  margin: 5px 0px 10px 0px;
}

.amountSummary {
  margin-top: 20px;

  display: inline-flex;
  > div {
    margin-right: 20px;
  }
}

.continue {
  margin-top: 20px;
}

.container {
  // width: 700px;
  .indent{
    margin-left: 20px;
  }
}

.title {
  margin-top: 20px;
  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 0.5;
    color: #34384b;
  }
}

.description {
  margin-top: 20px;
  color: #34384b;
  .acctnum{
    margin-right: 80px;
  }
  .bpi{
    margin-right: 85px;
  }
}



.title {
  margin-top: 0px;
}

.container {
  margin-top: 20px;
  > div {
    display: flex;
    .field {
      width: 200px;
    }
  }

  border-bottom: 1px solid #edeef2;
  margin-left: -35px;
  margin-right: -35px;
  padding: 0 35px;
}

.loader {
  width: 80%;
}

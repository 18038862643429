.pill {
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 12px;
}

.grass {
  background-color: var(--color-grass);
  color: #ffffff;
  font-weight: 500;
}

.cheddar {
  background-color: var(--color-cheddar);
  color: #ffffff;
  font-weight: 500;
}

.cement {
  background-color: var(--color-cement);
  color: #ffffff;
  font-weight: 500;
}

.deepRed {
  background-color: var(--color-deep-red);
  color: #ffffff;
  font-weight: 500;
}

.sky {
  background-color: var(--color-sky);
  color: #ffffff;
  font-weight: 500;
}

.tab-content {
  min-height: 100px;
  border: 1px solid #dcdcdc;
  border-top: none;
}
.customtab {
  display: flex;
  flex-direction: row;
  // margin:1%;
  margin: 0px 20px;
  justify-content: center;
}

.container {
  display: flex;
  margin: 1%;

  margin-top: 25px;
}
.navlink {
  span {
    font-size: 12x;
  }
  margin: 1px;
}
